import React, {Fragment, Component} from 'react';
import {Row} from 'jsxstyle';
import Helmet from 'react-helmet';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import PreFooter from '@components/PreFooter';
import Section from '@components/layout/Section';
import CardGrid from '@components/CardGrid';
import FloorPlanCard from '@components/FloorPlanCard';
import Headline from '@components/Headline';
import {BLUE, LIGHT_GREY, ORANGE, WHITE, TRANSPARENT} from '@styles/colors';
import AmenitiesRow from '@components/AmenitiesRow';
import ImageCallout from '@components/ImageCallout';
import Button from '@components/Button';
import Link from '@components/Link';
import {objectToString, addressToGoogleFriendlyAddress, getFloorplanName} from '@util';
import getFloorplansFromFloorplan from '@util/getFloorplansFromFloorplan';

import type {FloorplanType} from '@types-local';
import {AMENITIES_DEFAULT} from "../util/amenities";

type State = {
  randomSeed: number,
};

type Props = {
  data: {
    wagtail: {
      ...FloorplanType,
    },
  },
};

function generateFloorplanMeta(
  floorplan: FloorplanType,
): {metaTitle: string, metaDescription: string} {
  if (!floorplan) return;
  const {baths, beds, property, squareFeet, name: floorplanName} = floorplan;
  const {name: propertyName} = property;
  return {
    metaTitle: `Floorplan | ${beds} br | ${baths} ba | ${squareFeet} Sq Ft | ${propertyName}`,
    metaDescription: `Explore your next home. View floor plan details of this luxury ${beds} br ${baths} ba, ${squareFeet} Sq Ft apartment located in ${floorplanName} in ${propertyName}.`,
  };
}

export default class Floorplan extends Component<Props, State> {
  constructor(props) {
    super(props);
    const {data} = this.props;
    const {floorplan} = data.wagtail;
    const randomSeed = 0.5 - Math.random();
    this.state = {
      otherFloorplans: getFloorplansFromFloorplan(floorplan, randomSeed),
      floorplanMeta: generateFloorplanMeta(floorplan),
    };
  }

  render() {
    const {data} = this.props;
    const {floorplan} = data.wagtail;
    const {name, property, image} = floorplan;
    const {address, phoneNumber, name: propertyName} = property;
    const {metaTitle, metaDescription} = this.state.floorplanMeta;

    return (
      <Fragment>
        <Helmet>
          <html lang="en" />
          <title>{metaTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <Layout menuItemColor={BLUE} menuBackground="white">
          <Section>
            <ImageCallout
              eyebrow={propertyName}
              headline={getFloorplanName(name)}
              imageSrc={image && image.url && image.url}
              margin="120px auto 80px auto"
              textAlign="left"
              imgMaxHeight="600px"
            >
              <Link
                href={`https://www.google.com/maps/search/?api=1&query=${addressToGoogleFriendlyAddress(
                  objectToString(address),
                )}`}
                style={{
                  color: BLUE,
                  textDecoration: 'none',
                  fontWeight: 'bold',
                  marginBottom: '10px',
                }}
              >
                {objectToString(address)}
              </Link>
              <Link
                href={`tel: ${phoneNumber}`}
                style={{
                  color: BLUE,
                  fontWeight: 'bold',
                  marginBottom: '40px',
                }}
              >
                {phoneNumber}
              </Link>
              <Row>
                <Button
                  buttonText="view neighborhoods"
                  buttonLink="/neighborhoods/"
                  buttonColor={ORANGE}
                  margin="0 20px 0 0"
                />
                <Button
                  buttonText="view our instagram"
                  buttonLink="https://www.instagram.com/fetnernyc/"
                  buttonColor={TRANSPARENT}
                  copyColor={BLUE}
                  /* buttonBorder={`2px solid ${BLUE}`}
                  buttonHeight={46} */
                />
              </Row>
            </ImageCallout>
          </Section>
          <Section margin="0 0 50px 0">
            <AmenitiesRow amenities={AMENITIES_DEFAULT} />
          </Section>
          <Section background={LIGHT_GREY} padding="80px 0">
            <Headline>You also might like these floor plans</Headline>
            <CardGrid
              data={this.state.otherFloorplans}
              maxWidth="1360px"
              gridItem={FloorPlanCard}
              gridTemplateColumns={'repeat(auto-fit, minmax(310px, 1fr))'}
              gridGap="40px"
              margin="0 auto"
              itemBackground={WHITE}
            />
          </Section>
          <PreFooter
            headline="Interested in becoming a resident?"
            copy="Get in touch with us to find your perfect Fetner apartment."
          />
        </Layout>
      </Fragment>
    );
  }
}

export const query = graphql`
  query($salesforceId: String!) {
    wagtail {
      floorplan(salesforceId: $salesforceId) {
        name
        beds
        baths
        squareFeet
        salesforceId
        image {
          url
        }
        property {
          phoneNumber
          name
          floorplans {
            id
            name
            beds
            baths
            squareFeet
            salesforceId
            image {
              url
            }
          }
          address {
            street
            city
            state
            postalCode
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        meta
      }
    }
  }
`;
