// @flow

import type {FloorplanType} from '@types-local';

export default function getFloorplansFromFloorplan(
  floorplan: FloorplanType,
  randomSeed: number,
  numberOfFloorplans: number = 8,
) {
  let floorplansWithPropertyName;
  if (floorplan) {
    const {floorplans} = floorplan.property;
    if (Array.isArray(floorplans) && floorplans.length > 0) {
      floorplansWithPropertyName = floorplans
        .filter(f => f.salesforceId !== floorplan.salesforceId)
        .map(f => {
          let floorplanWithPropertyName = {};
          floorplanWithPropertyName = f;
          floorplanWithPropertyName.propertyName = floorplan.property.name;
          return floorplanWithPropertyName;
        });
    }
    if (
      Array.isArray(floorplansWithPropertyName) &&
      floorplansWithPropertyName.length > 0
    ) {
      const shuffle = floorplansWithPropertyName.sort(() => randomSeed);
      // $FlowFixMe
      const selected = shuffle.slice(0, numberOfFloorplans);
      if (selected.length > 0) {
        return selected;
      }
    }
  }
}
